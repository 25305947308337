.section-one {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
}

.div-Lattes {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

}

.div-Recommendation {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

}
.btn-Download{
    padding: 12px 12px;
    color: #fff;
    background-color: #3498DB;
    font-size: 18px;
    font-weight: 600;
    border-radius: 6px;
    border: none;
}