.buttonCommon {
    font-size: 18px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    width: 95%;
    height: 100%;
    padding-right: 10px;
}

.buttonCommon:hover {
    background-color: #2980b9;
}


#sidebar-navigator .navbar {
    background-color: #060b26;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
}

#sidebar-navigator .menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
}

#sidebar-navigator .nav-menu {
    background-color: #060b26;
    width: 215px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
}

#sidebar-navigator .nav-menu.active {
    left: 0;
    transition: 350ms;
}

#sidebar-navigator .nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 16px 8px 8px;
    list-style: none;
    height: 60px;
}

#sidebar-navigator .nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
    background-color: rgba(236, 235, 235, 0.2);
}

#sidebar-navigator .nav-text a:hover {
    background-color: #444a83ff!important;
}

#sidebar-navigator .selected {
    background-color: #1a83ff!important;
}

#sidebar-navigator .nav-menu-items {
    width: 100%;
}

#sidebar-navigator .navbar-toggle {
    height: 80px;
}

#sidebar-navigator span {
    margin-left: 5x;
}


#sidebar-navigator .logout-button {

    margin: 48px 16px 8px 8px;

    text-decoration: none;
    color: #FC0000;
    font-size: 16px;
    width: 180px;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
    background-color: rgba(236, 235, 235);
}
#sidebar-navigator .logout-button:hover {
    cursor: pointer;
    background-color: rgb(211, 210, 210);
}
