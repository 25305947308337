.custom-button-stages {

    color: #5C4EC9;
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    padding: 13px 25px;
    background-color: transparent;
    border: 1px solid #5C4EC9;
    border-radius: 40px;
    font-weight: 700;
    cursor: pointer;
}

@media screen and (max-width: 430px) {
    .custom-button-stages {

        width: 100%;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.btnCiencia.css-sghohy-MuiButtonBase-root-MuiButton-root{
        width: 100%;
        margin-right: 0px !important;
    }

    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
        padding-right: 50px ;
    }

}