
.textInput {
    margin: 5px 0!important;
    border: none;
}


.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    position: fixed !important;
}
