
.ButtonVoltar {
    
    color: #80CAFF;
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    padding: 13px 44px;
    background-color: transparent;
border: 3px solid #80CAFF;
    border-radius: 40px;
    font-weight: 700;
    cursor: pointer;
  
    }
  
  
    @media screen and (max-width: 375px) {
  
      .ButtonVoltar {
        padding: 10px 50px;
        }
  
        
  
  }
  
  @media screen and (max-width: 768px) {
    .ButtonVoltar {
      padding: 10px 30px;
    font-size: 16px;
    width: 100%;
      }
  }
    