.thankYouIndication {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    margin: 20px auto;

    p {
        max-width: 250px;
        margin-top: 10px;
    }
}

.titleThankYouIndication {
    color: #000273;
    text-align: center;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 28px */
    /* 56px */
}

.textThankYouIndication {
    color: #FFF;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.subTextThankYouIndication {
    color: #000273;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
}

.rectangle{
    border-radius: 10px;
    border: 1px solid #5C4EC9;
    background: #F7F6FF;
    box-shadow: 0px 4px 4px 0px rgba(92, 78, 201, 0.23);
}

.rectangle {
    padding: 30px 45px 35px 45px;
    background: #F7F6FF;
    border-radius: 10px;
    border: 1px solid #5C4EC9;
    box-shadow: 0px 4px 4px 0px rgba(92, 78, 201, 0.23);
    width: 635px;
    margin-top: 20px;
}
// botão 'VOLTAR E EDITAR SUA INDICAÇÃO'
    .divBotao{
        display: flex;
        justify-content: center;
    }


//
@media screen and (max-width: 430px) {
    .rectangle{
        width: 350px !important;
        padding: 20px 15px !important;
      }
}

@media screen and (max-width: 768px) {
    .rectangle{
        width: 730px ;
        padding: 20px 15px !important;
      }
}

