
.textInput {
    margin: 12px 0!important;
}

.InputWithCheckbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    width: 100%;
    justify-content: space-between;
}

