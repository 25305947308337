.previousRegistrationData {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;



    p {
        width: auto !important;
        margin-top: 10px;

        color: #000273;
        font-family: "Open Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 180%;

    }


}

.textPrevious {

    color: #000273;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 180%;
    width: 75%;

}

@media screen and (max-width: 430px) {

    .textPrevious {


        width: 100% !important;

    }

}