.thankYou {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    margin: 80px auto;

    p {
        max-width: 250px;
        margin-top: 10px;
    }
}

.titleThankYou {
    color: #654BCD;
    text-align: center;
    font-family: "Open Sans";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    /* 56px */
}

.textThankYou {
    color: #FFF;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.subTextThankYou {
    margin-top: 10px;
    margin-bottom: -30px;
    color: #FFF;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
}