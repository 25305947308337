.personalDataEditContainer {
    margin: 20px 0;
}

.personalDataEditRow {
    margin: 10px 0;
}

.formBlock {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
}

.infoTitular{
    display: flex;
    flex-direction: column;
}

.textDadospessoais {
    color: #000273 !important;
    font-family: "Open Sans" !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 190% !important;
    /* 34.2px */
}

.textDocuments {

    color: #000273;
    font-family: "Open Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 190%;
    /* 28.5px */

}

.textMotivos {

    color: #00162A !important;
    font-family: "Open Sans" !important;
    font-size: 15px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 190% !important;
    /* 28.5px */

}

.personalDataEditContainer .personalDataEditRow{
    min-height: 2.8rem;
}


.personalDataEditRow .MuiButton-sizeMedium{
    font-size: 10px !important;
    visibility: visible;
    background-color: transparent !important;
    border-color: #5C4EC9 !important;
    color: #5C4EC9 !important;
}
// .css-1rwt2y5-MuiButtonBase-root-MuiButton-root {

//     font-size: 10px !important;
//     visibility: visible;
//     background-color: transparent !important;
//     border-color: #5C4EC9 !important;
//     color: #5C4EC9 !important;

// }

@media screen and (max-width: 430px) {

    .textMotivos{
        line-height: 180% !important;
    }
    
}


#city-helper-text{
    bottom: -20px;
    position: relative;

}
#address-helper-text{
    bottom: -20px;
    position: relative;
}