.ConfirmPopupAvanca {

    position: absolute;

    z-index: 3;

    width: 295px;
    padding: 16px;
    gap: 40px;

    transform: translateX(300px);

    display: flex;
    justify-content: space-between;
    align-items: center;

    border-radius: 4px;
    background: #FFF !important;

    /* M3/Elevation Dark/3 */
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);

    h3 {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #5C4EC9 !important;
    }

    button {
        cursor: pointer;
        background: none;
        border: none;
    }

    @media screen and (max-width: 1088px) {

        display: none;

    }

 
    @media screen and (max-width: 1512px) {

        transform: translateX(270px);
        width: 275px;

        h3 {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.15px;
            color: #5C4EC9 !important;
        }

    }


}