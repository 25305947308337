
.ButtonSubmeter {
    
  background: #5C4EC9;
  color: #fff;
  text-align: center;
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  padding: 16px 60px;
  cursor: pointer;
  border: 3px solid;
  border-radius: 40px;
  
    }
  
  
    @media screen and (max-width: 375px) {
  
      .ButtonSubmeter {
        padding: 10px 50px;
        }
  
        
  
  }
  
  @media screen and (max-width: 768px) {
    .ButtonSubmeter {
      padding: 10px 30px;
      font-size: 16px;
      width: 100%;
      }
  }
    