.personalDataEditContainer {
    margin: 20px 0;
}

.personalDataEditRow {
    margin: 10px 0;
}

.formBlock {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
}

.formLabel {
    padding-top: 40px !important;
    display: flex;
}

.btnCiencia{
    font-size: 18px;
    font-weight: 800;
}

.textScienticProd {

    color: #00162A !important;
    font-family: "Open Sans" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 140% !important;
    /* 22.4px */
}