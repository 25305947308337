

.confirmPopup {

    position: absolute;

    z-index: 3;

    width: 660px;
    padding: 16px;
    gap: 40px;

    transform: translateX(60px);

    display: flex;
    justify-content: space-between;
    align-items: center;
    
    background: #D1D1D1;
    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3);
    border-radius: 4px;

    h3 {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #313033;
    }

    button {
        cursor: pointer;
        background: none;
        border: none;
    }
}

