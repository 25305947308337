

.personalDataEditContainer {
    margin: 20px 0;
}

.personalDataEditRow {
    margin: 10px 0;
}

.formBlock {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
}
