
.custom-button {
    
  color: #F1F9FF;
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  padding: 17px 67px;
  background-color: #5C4EC9;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  }


  @media screen and (max-width: 375px) {

    .custom-button {
      padding: 10px 50px;
      }

      

}

@media screen and (max-width: 768px) {
  .custom-button {
    padding: 10px 80px ;
    font-size: 16px;
    }
}
  