

.resetPasswordContainer {
    display: flex;
    justify-content: flex-end;
}

.resetPassword {
    background: none;
    border: none;
    cursor: pointer;
}

