
.custom-button-back{
    
    color: #5C4EC9;
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    padding: 13px 44px;
    background-color: transparent;
    border: 1px solid ;
    border-radius: 40px;
    font-weight: 700;
    cursor: pointer;
    }


    @media screen and (max-width: 430px) {

       
.custom-button-back{
    
    width: 100%;
    }

    }
