.progressHeader {

    h1 {
        margin-top: 50px;
        margin-bottom: 25px;
        margin-left: 60px;
        font-weight: 500;
        font-size: 36px;
        color: #06243D;
        max-width: 660px;
    }




    .circleContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;
    }

    .circleRow {
        position: absolute;
        width: 75%;
        height: 9px;

        z-index: 0;

        background: #75C5FF;
        margin-top: 38px;

        transition: all 0.2s ease-in-out;
    }

    // .circleRow.progress {
    //     background: #06243D;
    //     z-index: 1;
    //     margin-top: 34px;
    // }

    .circleBox {
        display: flex;
        flex-direction: column;
        gap: 18px;
        align-items: center;
        max-width: 100px;
    }

    .circle {
        width: 34px;
        height: 34px;
        background: #75C5FF;
        border-radius: 30px;
        margin: 5px 0;
        z-index: 2;
        transition: all 0.2s ease-in-out;
        padding: 8px;
    }



    .circle.act {
        width: 53px;
        height: 53px;
        background: #5C4EC9;
        border-radius: 30px;
        margin: 0;
        padding: 18px;
    }

    .text {
        text-align: center;
        font-family: "Open Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        /* 19.2px */
        transition: color 0.2s ease-in-out;
        /* Add a transition for smooth color change */
        /* Initial text color */
    }


    .circle.act+.text,
    .circle.act .text {
        color: #5C4EC9;
    }


    .circleContainer a {
        font-weight: 700;
        font-size: 14px;
        text-align: center;
        text-decoration: none;

        margin-top: 20px;
        color: #75C5FF;
    }


    .timelineText {
        z-index: 1;
        color: white;
    }

    @media screen and (max-width: 430px) {

        h1 {
            margin-top: 10px;
            margin-bottom: 25px;
            margin-left: 0px;
            font-weight: 500;
            font-size: 32px;
            color: #06243D;
            max-width: 660px;
        }

    }


    @media screen and (max-width: 750px) {

        .circleContainer {
            display: none;
        }

    }



}