* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: 'Open Sans', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1 {
  font-family: 'Rajdhani', sans-serif;
}

h2 {
  font-size: 18px;
}

body #root {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 100px;
  flex-direction: column;
  background: #F7F6FF;
  min-height: 87vh;
}

@media screen and (max-width: 430px) {

  .divInsti{
    width: auto;
  }

  .formContainer{
    padding: 20px 25px !important;
  }

  .divConteudoCand{
    padding: 10px 0px !important;
  }

  .formContainerCand{
    width: 325px !important ;
    padding: 15px 10px !important;
  }

  .formContainerThankYou{
    width: 350px !important;
    padding: 20px 15px !important;
  }

  .formContainerCiencia {
    width: 350px !important;
    padding: 20px 15px !important;
  }

  .formContainerTecnologia{

    width: 350px !important;
    padding: 20px 15px !important;

  }


  .row-gap {
    display: flex !important;
    gap: 20px !important;
    margin-top: 15px !important;
    justify-content: space-between !important;
    align-items: center !important;
    flex-direction: column !important;
  }

}

@media screen and (max-width: 768px) {

  .divTermos {
    width: 100%;
  }

  .formContainerHome {
    padding: 15px 15px !important;
  }

  .formContainerCand{
    padding: 20px 15px !important;
  }

  body #root {
    padding: 60px 30px;
  }

  .formContainerThankYou{
    width: 730px ;
    padding: 20px 15px !important;
  }

  .formContainerCiencia {
    width: 730px ;
    padding: 20px 15px !important;
  }

  .formContainerTecnologia{
    width: 730px ;
    padding: 20px 15px !important;
  }

}



.formContainer {
  padding: 30px 45px 53px 45px;
  background: #FFFFFF;
  border-radius: 10px;
  border: 1px solid #75C5FF;
  background: #F1F9FF;
  box-shadow: 0px 4px 4px 0px rgba(92, 78, 201, 0.23);
  max-width: 700px;
  margin-top: 20px;
}



.formContainerCand{
  padding: 44px;
  border-radius: 10px;
  border: 1px solid #5C4EC9;
  background: #F7F6FF;
  box-shadow: 0px 4px 4px 0px rgba(92, 78, 201, 0.23);
  width: 780px;
}

.formContainerThankYou {
  padding: 30px 45px 35px 45px;
  background: #FFFFFF;
  border-radius: 10px;
  border: 1px solid #75C5FF;
  background: #75C5FF;
  box-shadow: 0px 4px 4px 0px rgba(92, 78, 201, 0.23);
  width: 635px;
  margin-top: 20px;
}

.formContainerCiencia {
  padding: 44px;
  border-radius: 10px;
  border: 1px solid #78C6FF;
  background: #F1F9FF;
  box-shadow: 0px 4px 4px 0px rgba(92, 78, 201, 0.23);
  width: 730px;
}

.formContainerTecnologia {
  padding: 44px;
  border-radius: 10px;
  border: 1px solid #78C6FF;
  background: #F1F9FF;
  box-shadow: 0px 4px 4px 0px rgba(92, 78, 201, 0.23);
  width: 730px;
}

.formContainerHome {
  padding: 30px 45px 53px 45px;
  background: #FFFFFF;
  border-radius: 10px;
  border: 1px solid #75C5FF;
  background: #F1F9FF;
  box-shadow: 0px 4px 4px 0px rgba(92, 78, 201, 0.23);
  max-width: 745px;
  margin-top: 20px;
}

.formContainer form {
  margin-top: 20px;
}

.row-gap {
  display: flex;
  gap: 20px;
  margin-top: 15px !important;
  justify-content: space-between !important;
  align-items: center;
}


.row-gap.last {
  justify-content: flex-end !important;
  padding: 30px 0px 60px 0px;
}

#adminInstitution header {
  margin-left: 215px;
}

#adminPage header {
  margin-left: 215px;
}