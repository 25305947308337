

.confirmEmail {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    border: 1px solid #5C4EC9;
    box-shadow: 0px 4px 4px 0px rgba(92, 78, 201, 0.23);

    margin: 80px auto;
    padding: 60px 30px!important;
    
    p {
        max-width: 400px;
        margin: 40px 0;
    }
}

.titleConfirmEmail {
    color: #000273;
    text-align: center;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 28px */
    /* 56px */
}

.subConfirmEmail {
    color: #000273;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
}


