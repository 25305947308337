.errorHelperText {
    color: #d32f2f;
    font-size: 12px;
    font-weight: 400;
    font-family: "Roboto", "Open Sans", sans-serif;
    margin: 6px 0 30px 0;
}

#controlled-radio-buttons-group {
    color: #06243D;
}

.divInsc {
    background-color: #EDEBFF;
    border-radius: 20px;
    margin: 12px 0px;
    display: flex;
    align-items: center;
}

.regulamentoTexto {
    margin: 30px 0 20px 0;

    a {
        color: #23ACD8;
        text-decoration: none;
        font-weight: 700;
    }


}

.css-1h7anqn{

    display: flex;
    flex-direction: row !important;
}

.homeText {
    color: #000273;
    font-size: 19px;
    font-style: normal;
    min-width: 4rem;
    font-weight: 400;
    line-height: 190%;
    margin-left: 7px !important;

}

.candText {
    color: #000273;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 190%;
    margin-left: 7px !important;

}

.confirm-senha {
    width: 225px;
}

@media screen and (max-width: 430px) {

   

    .candText {
        font-size: 14px;
    }

}